<template>
	<v-container fluid style="max-width: 1600px">
		<h2 class="text-center">
			{{ $t('sponsors.officialSponsors') }}
		</h2>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('sponsors.officialSponsors'),
		}
	},
	computed: {},
}
</script>
